import { Component } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http'
import { environment } from '../environments/environment'
import { FormGroup, FormControl } from '@angular/forms';

export type FormInterface = { digital_signature : string };

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'online-policy-verification';

  digiSign: any;
  digital_signature: any;
  errMsg: string
  isError: boolean = false;
  digiSignIsError: boolean = false; 
  _spinner: boolean = false;
  digiSignError : string;
  onlineDigiSignIsShown: boolean = false;
  digiSignIsShown: boolean = false ;
  onlineDigiSign: any;
  onlineDigiSignErrMsg: string;
  onlineDigiSignIsError: boolean = false;

  constructor( private http: HttpClient) {
    this.onlineDigiSignIsShown = false;
    this.digiSignIsShown = false;
    this.isError = false;
    this.digiSignIsError = false;
    this.onlineDigiSignIsError = false;
    this.errMsg = ''; //required check
    this. digiSignError = '';
    this.onlineDigiSignErrMsg = '';
    //this.digital_signature = 'DI-2020%2FFA305146';
  }

  ngOnInit(){
    //this.onSubmit(this.digital_signature);
  }


  onSubmit(digital_signature) {
    this.isError = false;
    this.digiSignIsError = false;
    this.onlineDigiSignIsError = false;
    this._spinner = true;
    this.onlineDigiSignIsShown = false;
    this.digiSignIsShown = false;
    this.errMsg = '';
    this.onlineDigiSignErrMsg = '';
    this. digiSignError = '';

    if(digital_signature == null){
      this.isError = true;
      this.digiSignIsError = false;
      this.onlineDigiSignIsError = false;
      this._spinner = false;
      this.errMsg = 'This field is required';
      console.log("errMsg", this.errMsg)
    }
    if(environment.apiUrl){
      this.http.get(environment.apiUrl + '/v1/policy/verify?digital_signature=' + digital_signature)
      .subscribe(data => {
        this.onlineDigiSignIsError = false;
        this._spinner = false;
        this.digiSignIsShown = true;
        this.onlineDigiSignIsShown = false;
        this.isError = false;
        this.digiSign = data; 
      }, err => {
        if (err.status == "400"){
          this.isError = false;
          this.onlineDigiSignIsError = false; 
          this.digiSignError = "Given details does not match any policy";
          //this.digiSignError = err.error.error_message;
          //this.digiSignError = err.error.errors.digital_signature[0];
          console.log("digiSignError", this.digiSignError)
          this.digiSignIsError = true;       
          this._spinner = false;
          //this.errMsg = err.error_message
        }
      })
    }
    if(environment.onlineDhivehiInsuranceApiUrl){ 
      this.http.get(environment.onlineDhivehiInsuranceApiUrl + '/verify?code=' + digital_signature)
      .subscribe(data => {
       this.digiSignIsError = false; 
        this._spinner = false;
        this.onlineDigiSignIsShown = true;
        this.digiSignIsShown = false; 
        this.isError = false;
        this.onlineDigiSign = data;
      },err => {
        if(err.status == "404"){
          this.isError = false;
          this.digiSignIsError = false;    
          this.onlineDigiSignIsError = true; 
          this.onlineDigiSignErrMsg = "Given details does not match any policy";
          console.log("onlineDigiSignErrMsg", this.onlineDigiSignErrMsg)
          //console.log("message", err.error.message)
          this._spinner = false;
        }
      })
    }
  }
}
