<app-navbar></app-navbar>
<br>
<div class="container">
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
        <div class="form-group">
          <label for="digital_signature">To verify your policy please enter the digisign code on your policy</label>
          <input type="text" class="form-control"  [(ngModel)]="digital_signature" name="digital_signature">
          <div class="text-danger" *ngIf="isError">
            <span>{{errMsg}}</span>
          </div>
          <div class="text-danger" *ngIf="digiSignIsError || onlineDigiSignIsError ">
            <span>{{digiSignError}}</span>
          </div>
          <!-- <div class="text-danger" *ngIf="onlineDigiSignIsError && !digiSign && !isError && !digiSignIsError">
            <span>{{onlineDigiSignErrMsg}}</span>
          </div> -->
        </div>        
        <button type="submit" class="btn btn-lg btn-block" (click)="onSubmit(digital_signature)" style="background-color: #00bab5; color: white;" >Verify
          <span class="spinner-border spinner-border-sm" *ngIf="_spinner"></span>
        </button>
    </div>
    <div class="col-md-3"></div>
  </div><br/>
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
      <div class="card" *ngIf="digiSign && digiSignIsShown">
        <div class="card-body">
            <div style="font-style: italic; font-weight: bold;">Policy Type:
              <span style="float: right; font-style: italic; font-weight: lighter;">{{digiSign.policy_name}}</span>
            </div>
            <div style="font-style: italic; font-weight: bold;" *ngIf="!digiSign.policy_number == null">Policy Number:
              <span style="float: right; font-style: italic;font-weight: lighter;">{{digiSign.policy_number}}</span>
            </div>
            <div style="font-style: italic; font-weight: bold;">ID or Registration Number:
              <span style="float: right; font-style: italic; font-weight: lighter;">{{digiSign.policy_ID_or_registration_no}}</span>
            </div>
            <div style="font-style: italic; font-weight: bold;">Inception Date:
              <span style="float: right; font-style: italic; font-weight: lighter;">{{digiSign.policy_inception_date | date}}</span>
            </div>
            <div style="font-style: italic; font-weight: bold;">Expiry Date:
              <span style="float: right; font-style: italic; font-weight: lighter;">{{digiSign.policy_expiry_date | date}}</span>
            </div>
            <div style="font-style: italic; font-weight: bold;">Insured Name:
              <span style="float: right; font-style: italic; font-weight: lighter;">{{digiSign.policy_insured_name}}</span>
            </div>
            <!-- <div style="font-style: italic; font-weight: bold;">Insured Interest:
              <span style="float: right; font-style: italic; font-weight: lighter;">{{digiSign.policy_interest_insured}}</span>
            </div> -->
            <div style="font-style: italic; font-weight: bold;">Status:
              <span class="text-danger" style="float: right; font-style: italic; font-weight: lighter;">{{digiSign.policy_state}}</span>
            </div>          
        </div>
      </div>

      <div class="card" *ngIf="onlineDigiSign && onlineDigiSignIsShown">
        <div class="card-body">
          <div style="font-style: italic; font-weight: bold;" *ngIf="!onlineDigiSign.policy_number === null">Policy Number:
            <span style="float: right; font-style: italic; font-weight: lighter;">{{onlineDigiSign.policy_number}}</span>
          </div>
          <div style="font-style: italic; font-weight: bold;">Proposal Number:
            <span style="float: right; font-style: italic; font-weight: lighter;">{{onlineDigiSign.proposal_number}}</span>
          </div>
          <div style="font-style: italic; font-weight: bold;">Premium:
            <span style="float: right; font-style: italic; font-weight: lighter;">{{onlineDigiSign.currency}} {{onlineDigiSign.premium}}</span>
          </div>
          <div style="font-style: italic; font-weight: bold;">Inception Date:
            <span style="float: right; font-style: italic; font-weight: lighter;">{{onlineDigiSign.inception_at | date :'dd-MMM-yy h:mm a '}}</span>
          </div>
          <div style="font-style: italic; font-weight: bold;">Expiry Date:
            <span style="float: right; font-style: italic; font-weight: lighter;">{{onlineDigiSign.ends_at | date :'dd-MMM-yy h:mm a '}}</span>
          </div> <hr/>
          <div>
            <div style="font-style: italic; font-weight: bold; text-align: center;" class="text-muted">INSURED<hr></div>
            <div style="font-style: italic; font-weight: bold;">Name:
              <span style="float: right; font-style: italic; font-weight: lighter;">{{onlineDigiSign.insured.name}}</span>
            </div>
            <div style="font-style: italic; font-weight: bold;">Email:
              <span style="float: right; font-style: italic; font-weight: lighter;">{{onlineDigiSign.insured.email}}</span>
            </div>
            <div style="font-style: italic; font-weight: bold;">Mobile Number:
              <span style="float: right; font-style: italic; font-weight: lighter;">{{onlineDigiSign.insured.mobile}}</span>
            </div>
            <div style="font-style: italic; font-weight: bold;">Identification Number:
              <span style="float: right; font-style: italic; font-weight: lighter;">{{onlineDigiSign.insured.identification_number}}</span>
            </div>
          </div><hr/>
          <div>
            <div style="font-style: italic; font-weight: bold; text-align: center;" class="text-muted">BENEFICIARIES<hr></div>
            <div style="font-style: italic; font-weight: bold;">Name:
              <span style="float: right; font-style: italic; font-weight: lighter;">{{onlineDigiSign.beneficiaries[0].insured_name}}</span>
            </div>
            <div style="font-style: italic; font-weight: bold;">Gender:
              <span style="float: right; font-style: italic; font-weight: lighter;">{{onlineDigiSign.beneficiaries[0].gender}}</span>
            </div>
            <div style="font-style: italic; font-weight: bold;">Date of Birth:
              <span style="float: right; font-style: italic; font-weight: lighter;">{{onlineDigiSign.beneficiaries[0].dob}}</span>
            </div>
            <div style="font-style: italic; font-weight: bold;">Passport Number:
              <span style="float: right; font-style: italic; font-weight: lighter;">{{onlineDigiSign.beneficiaries[0].passport}}</span>
            </div><hr>
          </div>
          <div style="font-style: italic; font-weight: bold;">Status:
            <span class="text-danger" style="float: right; font-style: italic; font-weight: lighter;">{{onlineDigiSign.status}}</span>
          </div>          
        </div>
      </div>
    </div>
    <div class="col-md-3"></div>
  </div>
</div>